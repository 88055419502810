<template>
    <div class="container">
        权限验证
    </div>
</template>


<script>
export default {
  name:"auth",
  data() {
    return {};
  }
};
</script>


<style lang="less" scoped>
.container {
  height: calc(100% - 65px);
  background-color: #808080;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  color: white;
}
</style>
